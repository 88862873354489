import React, { useEffect, useState } from 'react'
import Layout1 from '../../Layouts/Layout1'
import axiosInstanceAuth from '../../apiInstances/axiosInstanceAuth';
import { toast } from 'react-toastify';
import { Badge, Card, CardContent } from '@mui/material';
import { Calendar, MapPin, Mail, Home, Clock, DollarSign, Phone } from 'lucide-react';


const Appraisal = () => {
  const [appraisalDetails, setAppraisalDetails] = useState([])
  useEffect(() => {
    getAppraisalDetails()
  }, [])
  const getAppraisalDetails = async () => {
    await axiosInstanceAuth
      .get('/get/appraisal')
      .then((res) => {
        if (res.data.status) {
          setAppraisalDetails(res.data.data)
        } else {
          toast.error(res.data.message)
        }
      })
      .catch((error) => {
        console.log("error", error)
        toast.error(error.message)
      })
  }


  const PropertyCard = ({ property }) => {
    const { createdAt, first_name, last_name, mobile_no, address, email, type, timeframe, priceRange, reason } = property

    return (
      <Card className="relative overflow-hidden h-full">
        <div
          className="!absolute bg-[#dc0020] px-2 py-1 rounded-xl top-4 right-4 text-[12px] font-semibold text-white"
        >
          {reason}
        </div>
        <CardContent className="pt-12 pb-6 h-full flex flex-col">
          <div className="space-y-4 flex-grow">
            <div className="flex items-center gap-2 text-muted-foreground">
              <Calendar className="h-4 w-4 flex-shrink-0 font-semibold" color='#dc0020' />
              <span className="text-sm">{createdAt.split("T")[0]}</span>
            </div>

            <h3 className="font-semibold text-lg">{first_name} {last_name}</h3>
            <div className='flex flex-col gap-2 items-start justify-between'>
              <div className="space-y-2">
                <div className="flex items-center gap-2 text-muted-foreground">
                  <MapPin className="h-4 w-4 flex-shrink-0 font-semibold" color='#dc0020' />
                  <span className="text-sm">{address}</span>
                </div>
                <div className="flex items-center gap-2 text-muted-foreground">
                  <Mail className="h-4 w-4 flex-shrink-0 font-semibold" color='#dc0020' />
                  <span className="text-sm">{email}</span>
                </div>
                <div className="flex items-center gap-2 text-muted-foreground">
                  <Phone className="h-4 w-4 flex-shrink-0 font-semibold" color='#dc0020' />
                  <span className="text-sm">{mobile_no}</span>
                </div>
              </div>
              <div className="space-y-2 mt-auto">
                <div className="flex items-center gap-2">
                  <Home className="h-4 w-4 flex-shrink-0 font-semibold" color='#dc0020' />
                  <span className="text-sm">Type: {type}</span>
                </div>

                <div className="flex items-center gap-2">
                  <Clock className="h-4 w-4 flex-shrink-0 font-semibold" color='#dc0020' />
                  <span className="text-sm">Time Frame: {timeframe}</span>
                </div>

                <div className="flex items-center gap-2">
                  <DollarSign className="h-4 w-4 flex-shrink-0 font-semibold" color='#dc0020' />
                  <span className="text-sm">Price Range: {priceRange?.min} - {priceRange?.max} </span>
                </div>
              </div>
            </div>
          </div>
        </CardContent>
      </Card>
    )
  }

  return (
    <Layout1>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-4">
        {appraisalDetails.map((listing, index) => (
          <PropertyCard key={index} property={listing} />
        ))}
      </div>

      {/* <div className="min-w-[900px] bg-white rounded-2xl shadow-md my-10">
        <div className='overflow-x-auto'>

          <table className="w-full table-auto">
            <thead>
              <tr>
                <th className="p-4 text-start text-[#262626] font-bold text-sm lg:text-base">Date</th>
                <th className="p-4 text-start text-[#262626] font-bold text-sm lg:text-base">Full Name</th>
                <th className="p-4 text-start text-[#262626] font-bold text-sm lg:text-base">Email</th>
                <th className="p-4 text-start text-[#262626] font-bold text-sm lg:text-base">Mobile No.</th>
                <th className="p-4 text-start text-[#262626] font-bold text-sm lg:text-base">Address</th>
                <th className="p-4 text-start text-[#262626] font-bold text-sm lg:text-base">Type</th>
                <th className="p-4 text-start text-[#262626] font-bold text-sm lg:text-base">Time Frame</th>
                <th className="p-4 text-start text-[#262626] font-bold text-sm lg:text-base">Reason</th>
                <th className="p-4 text-start text-[#262626] font-bold text-sm lg:text-base">Price Min</th>
                <th className="p-4 text-start text-[#262626] font-bold text-sm lg:text-base">Price Max</th>
              </tr>
            </thead>
            <tbody>
              {appraisalDetails?.length > 0 &&
                appraisalDetails.map((d, index) => (
                  <tr key={index} className="border-t border-t-[#D4D4D4]">
                    <td className="p-4 text-[#262626] font-semibold text-sm lg:text-base">
                      {d.createdAt.split("T")[0]}
                    </td>
                    <td className="p-4 text-[#262626] font-medium text-xs md:text-sm lg:text-base">
                      {d.first_name} {(d?.first_name?.length + d.last_name.length) > 12 ? <div>{d.last_name}</div> : d.last_name}
                    </td>
                    <td className="p-4 text-[#262626] font-medium text-xs md:text-sm lg:text-base">
                      {d.email}
                    </td>
                    <td className="p-4 text-[#262626] font-medium text-xs md:text-sm lg:text-base">
                      {d.mobile_no}
                    </td>
                    <td className="p-4 text-[#262626] font-medium text-xs md:text-sm lg:text-base">
                      {d.address}
                    </td>
                    <td className="p-4 text-[#262626] font-medium text-xs md:text-sm lg:text-base">
                      {d.type}
                    </td>
                    <td className="p-4 text-[#262626] font-medium text-xs md:text-sm lg:text-base">
                      {d.timeframe}
                    </td>
                    <td className="p-4 text-[#262626] font-medium text-xs md:text-sm lg:text-base">
                      {d.reason}
                    </td>
                    <td className="p-4 text-[#262626] font-medium text-xs md:text-sm lg:text-base">
                      {d?.priceRange?.min}
                    </td>
                    <td className="p-4 text-[#262626] font-medium text-xs md:text-sm lg:text-base">
                      {d?.priceRange?.max}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div> */}
    </Layout1>
  )
}

export default Appraisal